import { ERROR } from '~/constants/common'

export default defineNuxtPlugin((nuxtApp) => {
  const { $axios, $ua, ssrContext } = nuxtApp as Record<string, any>
  if ($axios && $ua) {
    $axios.setHeader('os', $ua.os())
    $axios.setHeader('device', $ua.deviceType())
    $axios.setHeader('browser', $ua.browser())
  }

  if (ssrContext) {
    $axios.setBaseURL(ssrContext?.runtimeConfig?.public?.API_BASE_URL)
  } else {
    $axios.setBaseURL(window.location.origin)
  }

  $axios.onRequest((config: any) => {
    if (ssrContext) {
      config.headers.common.cookie = `host=bumble; ${config.headers.common.cookie}`
    }

    return config
  })

  $axios.interceptors.response.use(function (response: { config: { headers: any; url: any } }) {
    // Just log on server
    if (process.env.CHECK_SSR_CONTEXT && ssrContext) {
      const headers = response.config.headers

      const requestInfor = {
        IP: headers['x-forwarded-for'] || 'IP cannot detected',
        country: headers['cf-ipcountry'] || 'country cannot detected',
        os: headers.os,
        device: headers.device,
        browser: headers.browser
      }

      console.log(`${response.config.url} - ${requestInfor.country} - ${requestInfor.IP} - ${requestInfor.device} - ${requestInfor.os} - ${requestInfor.browser}`)
    }
    return response
  }, function (error: { config: { bypassErrorToast: any }; response: { data: { message: any } } }) {
    const nuxtApp = useNuxtApp()
    if (!error?.config.bypassErrorToast) {
      nuxtApp.$alertMsg('error', error?.response?.data?.message || ERROR)
    }
    throw error
  })
})
